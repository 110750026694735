<template>
  <div>
    <b-button
      variant="primary"
      @click="testArray"
    >
      Test!
    </b-button>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  methods: {
    testArray() {
      const areaLeader = ['vl07-3671', 'vl08-3671']
      const indexSearch = []
      areaLeader.forEach(area => {
        const layanan = area.split('-')[0]
        const region = area.split('-')[1]

        indexSearch.push({
          area: region,
          leader: 'vAhqgfXuXWHJ2oM4qA2t',
          vertikal: layanan,
        })
      })
    },
  },
}
</script>

<style>

</style>
